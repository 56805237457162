import { Button, Container } from "react-bootstrap";

export default function Voorwaarden() {
    return (
        <Container>
            <Button variant='outline-primary' href='/'><i className="fas fa-arrow-left"></i>Terug</Button>
            <h1>Algemene voorwaarden</h1>
            <p>Deze voorwaarden zijn van toepassing op alle diensten van AMOL subdomain. Door gebruik te maken van de diensten van AMOL subdomain gaat u akkoord met deze voorwaarden.</p>
            <h2>Artikel 1: Gegevens opslaan</h2>
            <p>Voor het maken van de quiz slaan wij het volgende van u op: Naam, vragen goed, vragen fout en gemiddelde.</p>
            <h2>Artikel 2: Gegevens delen</h2>
            <p>Wij delen uw gegevens niet met derden.</p>
            <p>De uitslagen zullen alleen te zien zijn door instructeurs, management of software developers</p>
            <h2>Artikel 3: Aansprakelijkheid</h2>
            <p>Wij zijn niet aansprakelijk voor schade die is ontstaan door het gebruik van onze diensten.</p>
            <h2>Artikel 4: Wijzigingen</h2>
            <p>Wij behouden het recht om deze voorwaarden te wijzigen.</p>
            <h2>Artikel 5: Contact</h2>
            <p>Heeft u vragen over deze voorwaarden, neem dan contact met ons op.</p>
        </Container>
    )
}