import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, Image } from "react-bootstrap";
import CheckCookie from "../../../components/CheckCookie";
import { SendAnswersToDiscordWebhook } from "../../../components/DiscordMessages";
import { useNavigate } from "react-router-dom";

export default function QuizVragen(){
    const username = CheckCookie();
    const navigate = useNavigate();

    const [Vraag, SetVraag] = useState(0);
    const [vragenarray, setVragenArray] = useState([0]);
    const [Vragen, SetVragen] = useState([]);
    useEffect(() => {
      fetch('/vragen/vragen.json')
        .then(response => response.json())
        .then(data => {
          SetVragen(data);
          const tempArray = [0];
          while (tempArray.length < 10) {
            const random = Math.floor(Math.random() * data.length);
            if (tempArray.indexOf(random) === -1){
              tempArray.push(random);
            }
          }
          setVragenArray(tempArray);
        });
    }, []);

    const [goed, SetGoed] = useState(0);
    const [fout, SetFout] = useState(0);
    if (Vragen.length < 1) {
        return
    }

    const Next = (e) => {
      e.preventDefault();

      if (Vraag === 0) {
        if (e.target.value.toLowerCase() === "nee"){
          const gemiddelde = Math.round(((goed / vragenarray.length) * 9) + 1);
          Cookies.set("gemiddelde", gemiddelde, { expires: 1 });
          Cookies.set("goed", goed, { expires: 1 });
          Cookies.set("fout", fout+1, { expires: 1 });
          SendAnswersToDiscordWebhook(username, goed, fout+1, gemiddelde);
          navigate("/quiz/uitslag");
          return
        }
      }

      if (e.target.value.toLowerCase() === Vragen[vragenarray[Vraag]].goedantwoord.toLowerCase()){
        SetGoed(goed+1);
        // toast.success("Dat was het goede antwoord, op naar de volgende.", 3000);
      }
      else {
        SetFout(fout+1);
        // toast.error("Helaas dat was het verkeerde antwoord volgende keer beter.", 3000);
      }
      SetVraag(Vraag+1);
    }

    if (Vraag === vragenarray.length || Vraag > vragenarray.length){
        const gemiddelde = Math.round(((goed / vragenarray.length) * 9) + 1);
        Cookies.set("gemiddelde", gemiddelde, { expires: 1 });
        Cookies.set("goed", goed, { expires: 1 });
        Cookies.set("fout", fout, { expires: 1 });
        SendAnswersToDiscordWebhook(username, goed, fout, gemiddelde);
        navigate("/quiz/uitslag");
        return
    }

    return (
      <div>
        <h3>Je bent bij vraag {Vraag+1} van de {vragenarray.length}.</h3>
        <h5>Ingelogd als {username}.</h5>
        <div className='text-center'>
            <div className="mb-3">
                <h1>{Vragen[vragenarray[Vraag]].vraag}</h1>
                <ButtonGroup>
                  {Vragen[vragenarray[Vraag]].antwoorden.map((antwoord) => (
                      <Button key={antwoord} onClick={Next} value={antwoord}>{antwoord}</Button>
                  ))}
                </ButtonGroup>
            </div>
        </div>
        <Image src={Vragen[vragenarray[Vraag]].img} />
      </div>
    )
}