import { useState } from "react";
import { Button, Card, Container, Accordion } from "react-bootstrap";

export default function FAQ() {
  return (
    <Container>
      <Card className="mt-3">
        <Card.Title><h1 className="text-center">Dutch Army Force - Veel gestelde vragen/bugs/crashes/errors/ect.</h1></Card.Title>
        <Card.Subtitle className="text-center"><b>Tip: errors in het Engels zoeken en problemen in steekwoorden Nederlands.</b></Card.Subtitle>
        <Card.Body>
          <Button href="/keuze" variant="outline-primary"><i className="fas fa-arrow-left"></i>Terug</Button>
          {Questions()}
        </Card.Body>
      </Card>
    </Container>
  );
}

function Questions() {
  const [search, setSearch ] = useState("");

  // const Searches = () => {
    
  // }

  return (
    <>
      {/* TODO Filter werkend maken. */}
      <input type="text" className="form-control mt-3" value={search} onChange={e => setSearch(e.target.value)} placeholder="Zoek naar een vraag..." />
      <div className="text-muted">Zoekbalk werkt nog niet.</div>
      <Accordion id="questions">
        <hr />
        <h2 className="text-center">Out-Game</h2>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Waarom kom ik niet in TaskForceRadio?</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>Zit je in de DAF server?</li>
              <li>Heb je de plugin geïnstalleerd?</li>
              <li>Heb je de plugin geactiveerd?</li>
              <li>Krijg je de melding dat het wachtwoord niet klopt?</li>
            </ul>
            <p>Om te beginnen moet je in de DAF server zitten, je moet kunnen rondlopen om ook te kunnen verbinden met het kanaal.</p>
            <p>Als je niet in TaskForceRadio komt, kan het zijn dat je de plugin niet hebt geïnstalleerd. Download de plugin <a className="link" href="https://cdn.discordapp.com/attachments/693066693676892160/1119689831874842646/task_force_radio.ts3_plugin?ex=6624046a&is=66118f6a&hm=578d3a540f7327c020bf715ad6a51725eca118bd929fec664e0a2eb6db9f2fe5&" target="_blank" rel="noreferrer">hier</a>.</p>
            <p>Als je de plugin hebt geïnstalleerd, kan het zijn dat je de plugin niet hebt geactiveerd. Ga naar de instellingen van TeamSpeak en activeer de plugin.</p>
            <p>Als de plugin geactiveerd is, en krijgt je de melding dat het wachtwoord niet klopt, dan moet je dit aangeven in bijvoorbeeld daf-chat in de discord.<br/>Dit kan zijn dat iets fout zit in de server.</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Niet de nieuwste modpack of nog niet geinstalleerd?</Accordion.Header>
          <Accordion.Body>
            <p>Als je niet de nieuwste modpack hebt of nog niet hebt geïnstalleerd, kan je dit doen via de <a className="link" href="https://discord.com/channels/635084507002830858/693066693676892160" target="_blank" rel="noreferrer">#updates</a> kanaal in discord.</p>
            <p>Hier installeer je de preset (De Arma_3_Preset_DAF[versie].html bestand) als dit bestand is geinstalleerd.</p>
            <p>Dan ga je naar <b>Arma 3</b> daar ga je naar het tabje <b>Mods</b>. Vervolgens ga je rechtsboven naar <b>preset</b>, dan staat onderin <b>import</b>.</p>
            <p>Dan krijg als je een popup krijg over <b>overwrite</b> dan doe je dit om zeker te weten om de nieuwste modpack te krijgen.</p>
            <p>Dan krijg als je een popup krijg over <b>subscribe</b> dan doe je dit. Daarna wacht je dat ze klaar zijn met downloaden dit kan even duren.</p>
          </Accordion.Body>
        </Accordion.Item>
        <hr />
        <h2 className="text-center">In-Game</h2>
        <Accordion.Item eventKey="2">
          <Accordion.Header>You cannot play/edit this mission; it is dependent on downloadable content that has been deleted.</Accordion.Header>
          <Accordion.Body>
            <p>Check als je de nieuwste modpack hebt en de juiste aantal mods, komt dit niet overheen install de nieuwste mondpack. Dit kan via het <a className="link" href="https://discord.com/channels/635084507002830858/693066693676892160" target="_blank" rel="noreferrer">#updates</a> kanaal in discord</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Spawn in water bij inladen.</Accordion.Header>
          <Accordion.Body>
            <p>Als je in het water spawnt bij het inladen van de map, kan dit komen doordat je de mod van de map niet hebt.</p>
            <p>Controleer of je de nieuwste modpack hebt in <a className="link" href="https://discord.com/channels/635084507002830858/693066693676892160" target="_blank" rel="noreferrer">#updates</a>.</p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}