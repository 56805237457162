import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Button, Card, Container } from "react-bootstrap";
import CheckCookie from "../../../components/CheckCookie";

export default function QuizUitslag(){
  CheckCookie();
  const [gemiddelde, setGemiddelde] = useState(0);
  const [goed, setGoed] = useState(0);
  const [fout, setFout] = useState(0);

  useEffect(() => {
    setGemiddelde(Cookies.get("gemiddelde"));
    setGoed(Cookies.get("goed"));
    setFout(Cookies.get("fout"));
  }, []);

  return (
    <Container>
      <Card>
        <Card.Title><h1 className="text-center">Uitslag</h1></Card.Title>
        <Card.Body>
          {Uitslagtext(gemiddelde, goed, fout)}
        </Card.Body>
      </Card>
    </Container>
  )
}

function Uitslagtext(gemiddelde, goed, fout){
  if (!gemiddelde || !goed || !fout) return <div className="text-center"><h3>Loading...</h3></div>;
  if (gemiddelde===0 & goed===0 & fout===0) return <div className="text-center"><h3>Loading...</h3></div>;
  const aantal = parseInt(goed) + parseInt(fout);

  if (gemiddelde >= 6) {
    return (
      <div className="text-center">
        <div>
          <img src="/geslaagd.png" alt="Geslaagd" width="300" />
        </div>
        <h3>Goedgedaan, je bent geslaagd.</h3>
        <p>Je gemiddelde is {gemiddelde}.</p>
        <p>Je had {goed} goed en {fout} fout, van de totaal {aantal} vragen.</p>
        <p>Je hoort nu verdere instructies van je instructeur.</p>
        <Button href="/quiz" variant="primary">Terug naar start</Button>
      </div>
    )
  }
  else {
    return (
      <div className="text-center">
        <div>
          <img src="/gezakt.png" alt="Gezakt" width="200" />
        </div>
        <h3>Helaas, je bent niet geslaagd.</h3>
        <p>Je gemiddelde is {gemiddelde}, je hebt een 6 nodig om te slagen.</p>
        <p>Je had {goed} goed en {fout} fout, van de totaal {aantal} vragen.</p>
        <p>Je hoort nu verdere instructies van je instructeur.</p>
        <Button href="/quiz" variant="primary">Terug naar start</Button>
      </div>
    )
  }
}