import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import './styles/globals.css';
import { Container, Image, Navbar } from 'react-bootstrap';
import { Toaster } from 'react-hot-toast';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Voorwaarden from './pages/voorwaarden';
import Keuze from './pages/keuze';
import FAQ from './pages/faq';
import Kledingdracht from './pages/kledingdracht';
import Login from './pages/login';
import Quiz from './pages/quiz';
import QuizVragen from './pages/quiz/vragen';
import QuizUitslag from './pages/quiz/uitslag';

const Layout = ({ children }) => {
  return (
    <div>
      <header>
        <Navbar expand="lg" className="border-bottom box-shadow">
          <Container fluid>
            <Navbar.Brand href="/keuze">
              <Image className="d-inline-block align-top nav-logo" src="/daf.png" height={30} width={30} title="Logo"></Image>
              Dutch Army Force - Algemene Militaire Opleiding Luchtmobiel
            </Navbar.Brand>
          </Container>
        </Navbar>
      </header>
      <Container fluid>
        <Toaster />
        {children}
      </Container>
      <footer className="border-top footer text-muted">
        <Container fluid>
          <a href="https://dutcharmyforce.nl">Copyright &copy; {new Date().getFullYear()} - Dutch Army Force</a>
        </Container>
      </footer>
    </div>
  )
};


const AppRoutes = () => {
  const location = useLocation();
  const isAuthenticated = Cookies.get("username") ?? false;
  return (
    <Routes>
      <Route path="/" element={<Layout><Login /></Layout>} />
      <Route path="/keuze" element={<Layout><Keuze /></Layout>} />
      <Route path="/faq" element={<Layout><FAQ /></Layout>} />
      <Route path="/kledingdracht" element={<Layout><Kledingdracht /></Layout>} />
      <Route path="/voorwaarden" element={<Layout><Voorwaarden /></Layout>} />
      {/* Required login */}
      <Route path="/quiz" element={isAuthenticated?<Layout><Quiz /></Layout>:<Navigate to="/" state={{ from: location }} replace/>} />
      <Route path="/quiz/vragen" element={isAuthenticated?<Layout><QuizVragen /></Layout>:<Navigate to="/" state={{ from: location }} replace/>} />
      <Route path="/quiz/uitslag" element={isAuthenticated?<Layout><QuizUitslag /></Layout>:<Navigate to="/" state={{ from: location }} replace/>} />
    </Routes>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};

export default App;