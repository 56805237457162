export function SendDiscordWebhookMessage(message){
    fetch(process.env.REACT_APP_WEBHOOK_CODE, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({content: "De code voor de quiz is: ```"+message+"```"}),
    })
}

export function SendAnswersToDiscordWebhook(username, goed, fout, gemiddelde){
    fetch(process.env.REACT_APP_WEBHOOK_SCORE, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({content: "Gebruiker: "+username+" heeft de quiz gemaakt en heeft "+goed+" goed en "+fout+" fout, gemiddeld een "+gemiddelde}),
    })
}