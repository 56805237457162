import { useEffect, useState } from 'react'
import { Button, ButtonGroup, Spinner } from 'react-bootstrap'
import CheckCookie from '../../components/CheckCookie';

export default function Keuze() {
  return (
    <div className='text-center'>
      <h3>Welkom bij AMOL subdomain.</h3>
      <span></span>
      {LoadButtons()}
    </div>
  )
}

function LoadButtons(){
  const [cookie, SetCookie] = useState(null);

  const data = CheckCookie();
  useEffect(() => {
    SetCookie(data);
  }, [data]);

  if (cookie == null){
    return <Spinner variant='primary'></Spinner>;
  }

  return (
    <>
    {cookie? <p>Ingelogd als student.</p>: ""}
    {cookie? (
      <ButtonGroup>
        <Button className='btn-lg' variant='primary' href='/quiz'><i className='fas fa-question'></i>Quiz</Button>
      </ButtonGroup>
    ):
      <ButtonGroup>
        <Button className='btn-lg' variant='primary' href='/kledingdracht'><i className='fas fa-user-check'></i>Kledingdracht</Button>
        <Button className='btn-lg' variant='primary' href='/faq'><i className='fas fa-bug'></i>FAQ</Button>
      </ButtonGroup>
    }
    </>
  )
}