import { Button, FloatingLabel, Form, FormCheck } from 'react-bootstrap'
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import { useState } from 'react';
import { SendDiscordWebhookMessage } from '../../components/DiscordMessages';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const [code, setCode] = useState();
  const navigate = useNavigate();

  const GenerateCode = (e) => {
    e.preventDefault();
    const code = Math.floor(Math.random() * 100000) + 10000;
    setCode(code);
    SendDiscordWebhookMessage(code);
  }

  const handleSubmit = (e) => {
      e.preventDefault();
      if (code === null || code === undefined) {
        toast.error("Vraag eerst code aan bij je instructeur.");
        return
      }

      if (!e.target.agreed.checked) {
          toast.error("We moeten je gegevens opslaan om te kunnen zien wie de quiz heeft ingevult.");
          return;
      }

      if (Number(e.target.code.value) !== code) {
        console.log(e.target.code.value, code);
        toast.error("De code die je hebt ingevult was niet goed.");
        return;
      }
      Cookies.set("username", e.target.naam.value, { expires: 1 });
      navigate("/keuze");
  }

  return (
    <div className='mt-5 text-center'>
      <div className="mb-3">
          <h3>Welkom bij AMOL subdomain.</h3>
      </div>
      <div style={{position: "relative", left: "50%", transform: "translateX(-50%)", maxWidth: "20%"}}>
          <Form onSubmit={handleSubmit} className="was-validated">
              <p>Vraag pas de code aan na het lezen van de <a href='/voorwaarden' className='link'>algemene voorwaarden</a>.</p>
              <Button variant='warning' onClick={GenerateCode} className="mb-3">Vraag code aan</Button>
              <FloatingLabel label="Code" className="mb-3">
                  <Form.Control type="number" id="code" placeholder="" required />
                  <Form.Control.Feedback type='invalid'>Deze code krijg je van je instructeur!</Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel label="Naam" className="mb-3">
                  <Form.Control type="text" id="naam" placeholder="" required />
                  <Form.Control.Feedback type='invalid'>Vul je echte naam in, je hoeft alleen voornaam.</Form.Control.Feedback>
              </FloatingLabel>
              <FormCheck.Input id="agreed" className="mb-3" required />
              <FormCheck.Label htmlFor="agreed">&nbsp;Akkoord met <a href='/voorwaarden' className='link'>algemene voorwaarden</a></FormCheck.Label>
              <br/>
              <Button variant='success' type='submit'>Starten</Button>
          </Form>
      </div>
    </div>
  )
}