import { useEffect, useState } from "react";
import { Badge, Button, ButtonGroup, Card, Carousel, Container } from "react-bootstrap";

export default function Kledingdracht() {
  const [kleding, setKleding] = useState([]);

  useEffect(() => {
    fetch('kleding/kleding.json')
      .then(response => response.json())
      .then(data => setKleding(data));
  }, []);

  return (
    <Container>
      <Card>
        <Card.Title><h1 className="text-center">Dutch Army Force - Kledingdracht</h1></Card.Title>
        <Card.Body>
          <Button href="/keuze" variant="outline-primary"><i className="fas fa-arrow-left"></i>Terug</Button>
          {Categorieen(kleding)}
          {Kledinglijst(kleding)}
        </Card.Body>
      </Card>
    </Container>
  );
}

function Categorieen(kleding) {
  if (!kleding || kleding.length === 0) return <div className="text-center"><h1>Loading...</h1></div>;
  return (
    <div className="text-center">
      <ButtonGroup>
        {Object.keys(kleding).map((key, index) => (
          <Button href={`#${key}`} variant="primary" className="m-1" key={index}>{key}</Button>
        ))}
      </ButtonGroup>
    </div>
  )
}

function Kledinglijst(kleding) {
  if (!kleding || kleding.length === 0) return <div className="text-center"><h1>Loading...</h1></div>;
  return (
    <div className="text-center text-dark">
      {Object.keys(kleding).map((key, index)=>(
        <div key={key} id={key}>
          <h1>{key}</h1>
          <Carousel interval={null}>
            {Object.values(kleding[key]).map((kledingstuk, kledingindex) => (
              <Carousel.Item key={kledingindex}>
                <img
                  style={{borderRadius: "10px"}}
                  className="d-block w-100"
                  src={`/kleding/${kledingstuk.afbeelding}`}
                  height={600}
                  onError={(e) => e.target.src = '/error.png'}
                  alt={kledingstuk.naam}
                />
                <Carousel.Caption>
                  <Badge bg="dark">
                    <h3>{kledingstuk.naam}</h3>
                    <p>{kledingstuk.type}</p>
                  </Badge>
                  <br />
                  <Badge bg={kledingstuk.required==="Geen"? "primary":"danger"}>Vereist: {kledingstuk.required}</Badge>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      ))}
    </div>
  )
}