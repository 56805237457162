import { Button } from "react-bootstrap";
import CheckCookie from "../../components/CheckCookie";

export default function Quiz() {
    CheckCookie();
    
    return (
        <div className='text-center'>
            <div className="mb-3">
                <h3>Welkom bij AMOL quiz.</h3>
                <span>De quiz is er om te kijken of je de handboeken hebt gelezen en begrijpt.</span>
                <br />
                <span>De uitslag is direct bekent, dit betekend niet dat de instructeur kan kiezen om nog steeds door te gaan.</span>
            </div>
            <div style={{position: "relative", left: "50%", transform: "translateX(-50%)", maxWidth: "20%"}}>
                <Button variant='success' href="/quiz/vragen">Starten</Button>
            </div>
        </div>
    )
}